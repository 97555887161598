<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas del Control de Entrega EPPS </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL CONTROL DE ENTREGA DEL EPPS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-circle fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaControl.length}}</span>
                            <br>
                            <span class="text-muted">Control de Entrega EPPS registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestion de Control de Entrega Epps</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name:'Proyectos'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarControlEntrega = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Control de Entrega Epps</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5">Control de Entrega Epps registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaControl" :fields="camposControlEntrega" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirmodalActualizarControlEntregaEpps(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarQuejas(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarControlEntrega" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5">Registro de Control de Entrega EPPS</span>
            </h6>
            <CButtonClose @click="modalRegistrarControlEntrega = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarControlEntrega)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosControlEntrega.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosControlEntrega.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosControlEntrega.nombrePasajero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosControlEntrega.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosControlEntrega.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="número de documento" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Número de documento:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de documento" v-model="datosControlEntrega.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="area" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Area de Trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el área de trabajo" v-model="datosControlEntrega.areaTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo de Trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el área de trabajo" v-model="datosControlEntrega.cargoTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de Epps</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividad()">
                            <i class="fas fa-plus fa-sm"></i> Agregar Epps
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosControlEntrega.listaEpps" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            <!-- <template #table-colgroup="cabeceraActividades">
                                <col v-for="field in cabeceraActividades.fields" :key="field.key" :style="{ width: field.key === 'descripcion' ? '20%' : field.key === 'horaFin' ? '20%' : field.key === 'actividad' ? '50%' : '10%'}">
                            </template> -->
                            <template v-slot:cell(numero)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.numero" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(descripcion)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.descripcion" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(motivo)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.motivo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(fecha)="rowActividad">
                                <b-form-input type="date" placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                            </template>

                            <template v-slot:cell(firma)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.firma" rows="3" max-rows="6"></b-form-input>
                            </template>

                            <template #cell(acciones)="param">
                                <b-button @click="quitarActividad(param)" v-if="datosControlEntrega.listaEpps.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarControlEntrega = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarControlEntregaEpps" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar lote de productos</span></h6>
            <CButtonClose @click="modalActualizarControlEntregaEpps = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarControlEntrega)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarControl.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarControl.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarControl.nombrePasajero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosControlEntrega.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarControl.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="número de documento" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Número de documento:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de documento" v-model="datosActualizarControl.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="area" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Area de Trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el área de trabajo" v-model="datosActualizarControl.areaTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo de Trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo de trabajo" v-model="datosActualizarControl.cargoTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de Epps</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividad()">
                            <i class="fas fa-plus fa-sm"></i> Agregar Epps
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosActualizarControl.listaEpps" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            <!-- <template #table-colgroup="cabeceraActividades">
                                <col v-for="field in cabeceraActividades.fields" :key="field.key" :style="{ width: field.key === 'descripcion' ? '20%' : field.key === 'horaFin' ? '20%' : field.key === 'actividad' ? '50%' : '10%'}">
                            </template> -->
                            <template v-slot:cell(numero)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.numero" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(descripcion)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.descripcion" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(motivo)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.motivo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(fecha)="rowActividad">
                                <b-form-input type="date" placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                            </template>

                            <template v-slot:cell(firma)="rowActividad">
                                <b-form-input placeholder="Ingrese la actividad" v-model.lazy="rowActividad.item.firma" rows="3" max-rows="6"></b-form-input>
                            </template>


                            <template #cell(acciones)="param">
                                <b-button @click="quitarActividad(param)" v-if="datosControlEntrega.listaEpps.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarControlEntregaEpps = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import TipoDocumentos from "@/helpers/TipoDocumentos";
import firebase from 'firebase';
const dbControlEntrega = firebase.firestore().collection('controlEntrega');
const da = firebase.firestore().collection('proyectos');

export default {
    data() {
        return {
            disabled: false,
            listaControl: [],
            camposControlEntrega: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombrePasajero",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "numeroDocumento",
                    label: "Numero de Documento",
                    class: "text-center",
                },
                {
                    key: "areaTrabajo",
                    label: "Area de Trabajo",
                    class: "text-center",
                },
                {
                    key: "cargoTrabajo",
                    label: "Cargo",
                    class: "text-center",
                },

                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            cabeceraActividades: [{
                    key: 'numero',
                    label: 'N°',
                    class: 'text-center'
                }, {
                    key: 'descripcion',
                    label: 'Descripcion',
                    class: 'text-center'
                }, {
                    key: 'motivo',
                    label: 'Motivo',
                    class: 'text-center'
                }, {
                    key: 'cantidad',
                    label: 'Cantidad',
                    class: 'text-center'
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center'
                },
                {
                    key: 'firma',
                    label: 'Firma',
                    class: 'text-center'
                },
                 {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                }
                
            ],

            comboProyecto: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosControlEntrega: {
                idCliente: '',
                idProyecto: '',
                idTipoDocumento: '',
                nombrePasajero: '',
                areaTrabajo: '',
                cargoTrabajo: '',
                numeroDocumento: '',
                listaEpps: [{
                    numero:'',
                    descripcion: '',
                    motivo: '',
                    cantidad: '',
                    fecha: moment().format('YYYY-MM-DD'),
                    firma: '',
                }],
            },
            datosActualizarControl: {
                idCliente: '',
                idProyecto: '',
                idControlEntrega:'',
                idTipoDocumento: '',
                nombrePasajero: '',
                areaTrabajo: '',
                cargoTrabajo: '',
                numeroDocumento: '',
                listaEpps: [{
                    numero:'',
                    descripcion: '',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: '',
        

            }],
              },

            modalRegistrarControlEntrega: false,
            modalActualizarControlEntregaEpps: false,
            comboTipoDocumentos: [],
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        obtenerTipoDocumento() {
            let me = this;
            me.comboTipoDocumentos = TipoDocumentos;
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedActualizarForm.refs.proyecto.validate();
        },
        agregarActividad(k) {
            let me = this;
            me.datosControlEntrega.listaEpps.push({
                descripcion: '',
                motivo: '',
                cantidad: '',
                fecha: '',
                firma: '',
            })
        },
        quitarActividad(param) {
            let me = this;
            me.datosControlEntrega.listaEpps.splice(param.index, 1);
        },
        /* agregarActividadActualizar(k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.push({
                horaInicio: '',
                horaFin: '',
                actividad: ''
            })
        },
        quitarActividadActualizar(param, k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.splice(param.index, 1);
        }, */
        listarProyectos() {
            let me = this;
            da
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosControlEntrega.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            numeroDocumento: doc.data().numeroDocumento,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));

                });
        },
        agregarEpps() {
            let me = this;
            me.datosControlEntrega.listaEpps.push({
                descripcion: '',
                motivo: '',
                cantidad: '',
                fecha: '',
                firma: '',
            })
        },

        listarControlDeEntregaEpps() {
            let me = this;
            // console.log(me.datosControlEntrega);
            //return
            dbControlEntrega
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosControlEntrega.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaControl = [];
                    querySnapshot.forEach((doc) => {
                        me.listaControl.push({
                             idControlEntrega: doc.id,
                            nombrePasajero: doc.data().nombrePasajero,
                            idCliente: doc.data().idCliente,
                             numeroDocumento: doc.data().numeroDocumento,
                             areaTrabajo: doc.data().areaTrabajo,
                             cargoTrabajo: doc.data().cargoTrabajo,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaControl.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        blurActualizarTipoDocumento() {
            this.computedFormActualizar.refs['tipo de documento'].validate();
        },
        blurTipoDocumento() {
            this.computedForm.refs['tipo de documento'].validate();
        },
        registrarControlEntrega() {
            let me = this;
           // console.log(me.datosControlEntrega);
            //return
            me.disabled = true;
            dbControlEntrega.add({
                    idCliente: me.datosControlEntrega.idCliente,
                    idProyecto: me.datosControlEntrega.idProyecto,
                    listaEpps: me.datosControlEntrega.listaEpps,
                    nombrePasajero: me.datosControlEntrega.nombrePasajero,
                    idTipoDocumento: me.datosControlEntrega.idTipoDocumento,
                    numeroDocumento: me.datosControlEntrega.numeroDocumento,
                    areaTrabajo: me.datosControlEntrega.areaTrabajo,
                    cargoTrabajo: me.datosControlEntrega.cargoTrabajo,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarControlEntrega = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarControlEntrega() {
            let me = this;
            me.disabled = true;
            dbControlEntrega.doc(me.datosActualizarControl.idControlEntrega).update({
                    idCliente: me.datosActualizarControl.idCliente,
                    idProyecto: me.datosActualizarControl.idProyecto,
                    listaEpps: me.datosActualizarControl.listaEpps,
                    nombrePasajero: me.d.nombrePasajero,
                    idTipoDocumento: me.datosActualizarControl.idTipoDocumento,
                    numeroDocumento: me.datosActualizarControl.numeroDocumento,
                    areaTrabajo: me.datosActualizarControl.areaTrabajo,
                    cargoTrabajo: me.datosActualizarControl.cargoTrabajo,
                    estado: 2,
                  
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarControlEntregaEpps = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarQuejas(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el guía?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbControlEntrega.doc(param.item.idControlEntrega).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirmodalActualizarControlEntregaEpps(param) {
          this.datosActualizarControl.idControlEntrega= param.item.idControlEntrega;
          this.datosActualizarControl.idProyecto=param.item.idProyecto;
          this.datosActualizarControl.idTipoDocumento= param.item.idTipoDocumento;
          this.datosActualizarControl.nombrePasajero=param.item.nombrePasajero;
          this.datosActualizarControl.numeroDocumento=param.item.numeroDocumento;
          this.datosActualizarControl.areaTrabajo=param.item.areaTrabajo;
          this.datosActualizarControl.cargoTrabajo=param.item.cargoTrabajo;
          this.datosActualizarControl.listaControl=param.item.listaControl;
          this.modalActualizarControlEntregaEpps = true;
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetmodalRegistrarControlEntrega() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosControlEntrega.nombrePasajero = '';
            this.datosControlEntrega.numeroDocumento = '';
            this.datosControlEntrega.areaTrabajo = '';
            this.datosControlEntrega.cargoTrabajo = '';
            this.datosControlEntrega.listaControl='';
            this.datosControlEntrega.acciones = '';
            

        },
        resetmodalActualizarControlEntregaEpps() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarControlEntrega: function (val) {
            if (val == false) {
                this.resetmodalRegistrarControlEntrega();
            }
        },
        modalActualizarControlEntregaEpps: function (val) {
            if (val == false) {
                this.resetmodalActualizarControlEntregaEpps();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosControlEntrega.idCliente = this.datosActualizarControl.idCliente = user.idCliente;
            this.listarControlDeEntregaEpps();
            this.listarProyectos();
            this.obtenerTipoDocumento();
        }
    }

}
</script>
